import { MedicalTest } from './medical-test.model';

export interface Order {
  id?: number;
  user_id?: number;
  order_number?: number;
  telephone?: number;
  location?: string;
  executed_at?: string[];
  created_at?: string;
  status?: string;
  description?: string;
  note?: string;
  availability?: string;
  medical_tests?: MedicalTest[];
  medical_consultation?: boolean;
}
  