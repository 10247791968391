<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9">
  <div class="section">
    <div class="container">
      <form [formGroup]="editOrderForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-lg-8">
            <div class="sigma_form style-7">
              <div class="form-block">
                <h5>Client Information:</h5>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Name</label>
                      <i class="fal fa-user"></i>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Jane"
                        [attr.disabled]="true"
                        formControlName="name"
                      >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Email</label>
                      <i class="fal fa-at"></i>
                      <input 
                        type="email" 
                        class="form-control"
                        placeholder="email"
                        [attr.disabled]="true"
                        formControlName="email"
                      >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Date of birth</label>
                      <i class="fal fa-calendar-alt"></i>
                      <input 
                        type="text"  
                        data-provide="datepicker"
                        class="form-control"
                        placeholder="'Date of birth'"
                        [attr.disabled]="true"
                        formControlName="birthDate"
                      >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Phone No.</label>
                      <i class="fal fa-phone"></i>
                      <input 
                        type="text" 
                        class="form-control"
                        placeholder="+256742345678"
                        formControlName="telephone"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-block">
                <h5>Test Order Information:</h5>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Available for sample pickup</label>
                      <i class="fal fa-calendar-alt"></i>
                      <input 
                        type="text"  
                        data-provide="datepicker"
                        class="form-control"
                        formControlName="availability"
                      >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Status</label>
                      <select 
                        class="form-control"
                        placeholder="Status"
                        formControlName="status"
                      >
                        <option value="" disabled>Status</option>
                        <option *ngFor="let status of availableStatuses" [value]="status.status">{{ status.status }}</option>
                      </select>
                      <div *ngIf="submitted && f.status?.errors?.required" class="is-invalid">
                        Status required
                      </div>
                    </div>
                  </div>                  
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Precise Location</label>
                      <i class="fal fa-location-arrow"></i>
                      <textarea  
                        rows="5" 
                        class="form-control"
                        formControlName="location"
                      >
                      </textarea>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Additional Notes</label>
                      <textarea  
                        rows="5" 
                        value="" 
                        class="form-control"
                        placeholder="Note To Us(Optional)"
                        formControlName="note"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="sidebar style-10 mt-5 mt-lg-0">
              <!-- Booking Widget -->
              <div class="widget widget-booking">
                <h5 class="widget-title">Order Summary</h5>
                <div class="test-names-container">
                  <span class="order-summary-title pb-2">
                    {{addConsultation &&  medicalTests.length < 0 ? 'Test Name(s)' : 'Items Ordered'}}
                  </span>
                    <ul class="test-names">
                      <li *ngFor="let item of order.medical_tests let i = index;">
                        {{(i + 1) + '. ' + item.name }}
                      </li>
                      <li *ngIf="addConsultation"> 
                        {{ (medicalTests.length + 1) + '. ' }} Medical Consultation
                      </li>
                    </ul>
                </div>
                <hr>
                <ul>
                  <ng-container *ngFor="let field of ['availability', 'name', 'status', 'telephone']">
                    <li class="d-flex align-items-center justify-content-between">
                      <span class="order-summary-title">{{field | capitalise}}</span>
                      <span>{{f[field]?.value}}</span>
                    </li>
                  </ng-container>
                </ul>
                
                <hr>
                <ul>
                  <li 
                    *ngIf="medicalTests.length > 0"  
                    class="d-flex align-items-center justify-content-between">
                    <span class="order-summary-title">Total Tests</span>
                    <span>{{ order?.medical_tests?.length }}</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span class="order-summary-title">Amount Paid</span>
                    <span>{{ getTotalCost() + ' UGX' }}</span>
                  </li>
                </ul>
                <hr>
                <ul>
                  <li class="d-flex align-items-center justify-content-between">
                    <button type="submit" class="sigma_btn btn-block btn-sm mt-4">
                      Submit
                      <i class="fal fa-arrow-right ml-3"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
