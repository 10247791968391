import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { 
  AuthenticationService, 
  DataService, 
  DocumentService, 
  MedicalTestService 
} from '../../services';
import { first } from 'rxjs/operators';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { FilterDetails, MedicalTest, User } from '../../models';
import { ImageTransform, base64ToFile } from 'ngx-image-cropper';
import { ImageCropperComponent } from '../../components/image-cropper/image-cropper.component';

@Component({
  selector: 'app-add-medical-test',
  templateUrl: './add-medical-test.component.html',
  styleUrls: ['./add-medical-test.component.scss']
})
export class AddMedicalTestComponent implements OnInit {
  @ViewChild('imageCopperElement') imageCopperElement: ImageCropperComponent;
  addMedicalTestForm: FormGroup;
  submitted = false;
  loggedInUser: User;
  categories: FilterDetails[] = [];
  medicalTest: MedicalTest;
  isAllowedImage: boolean = true;
  uploadedImages: File[]= [];
  imageChangedEvent: any;
  croppedImages: string[] = [];
  existingImages: string[] = [];
  imagesToDelete: string[] = [];
  existingImageFiles: File[] = [];
  canvasRotation = 0;
  rotation?: number;
  translateH = 0;
  scale = 1;
  aspectRatio = 1;
  maintainAspectRatio: boolean = true;
  resizeToWidth: number = 600;
  cropperMinWidth: number = 600;
  onlyScaleDown: boolean = true;
  roundCropper: boolean = false;
  alignImage: 'left' | 'center' = 'center';
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  imageURL?: string;
  loading: boolean = false;
  cropper = {
    x1: 0,
    y1: 0,
    x2: 600,
    y2: 400
  };
  isSuperAdmin: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private medicalTestService: MedicalTestService,
    private dataService: DataService,
    private documentService: DocumentService
    ) { }

  ngOnInit(): void {
    if (this.authenticationService.currentUser()) {
      this.loggedInUser = this.authenticationService.currentUser()['user'];
      this.isSuperAdmin = this.loggedInUser.role.name === 'Admin';
    }
    this.categories = this.dataService.getFilterDetails();
    this.dataService.getMedicalTestToEdit().subscribe(medicalTest => {
      this.medicalTest = medicalTest as MedicalTest;
      if (this.medicalTest && this.medicalTest.images) {
        this.existingImages = this.medicalTest.images;
        this.dataService.getMedicalTestToEdit().subscribe(medicalTest => {
          this.medicalTest = medicalTest as MedicalTest;
          this.existingImages = this.medicalTest.images as string[];
        })
      }
    });
    const duration = this.medicalTest?.duration?.split(' ');
    this.addMedicalTestForm = this.formBuilder.group({
      name: [this.medicalTest ? this.medicalTest.name : '', Validators.required],
      description: [this.medicalTest ? this.medicalTest.description : '', Validators.required],
      price: [this.medicalTest ? this.medicalTest.price : '', [Validators.required, Validators.pattern('[0-9]*')]],
      partnerPrice: [this.medicalTest ? this.medicalTest.partner_price : '', Validators.pattern('[0-9]*')],
      category: [this.medicalTest ? this.medicalTest.category : '', Validators.required],
      duration: [this.medicalTest ? duration[1]: '', Validators.required],
      time: [this.medicalTest ? duration[0]: '', Validators.required],
    });
  }

  get f() {
    return this.addMedicalTestForm.controls;
  }

  onImageChange(event: any): void {
    this.documentService.onImageChange(
      event,
      this.imageCopperElement,
      this.isAllowedImage,
      (isAllowedImage: boolean, imageChangedEvent: any, croppedImage: string) => {
        this.isAllowedImage = isAllowedImage;
        this.imageChangedEvent = imageChangedEvent;
        if (isAllowedImage) {
          this.croppedImages.push(croppedImage);
        }
      });
  }

  onCropImage(croppedImage: string) {
    this.documentService.onCropImage(
      croppedImage, (uploadedImage: File) => 
      this.uploadedImages.push(uploadedImage)
    );
    this.croppedImages.push(croppedImage);
    this.isAllowedImage = true;
  }

  onRemoveImge(index: number, isExistingImage: boolean = false) {
    if (isExistingImage && this.medicalTest && this.medicalTest.images) {
      const removedImage = this.medicalTest.images[index];
      this.medicalTest.images.splice(index, 1);
      this.imagesToDelete.push(removedImage);
    } else {
      this.croppedImages.splice(index, 1);
    }
  }
  
  ngOnDestroy() {
    const bodyElement = document.querySelector('.body');
    if (bodyElement) {
      bodyElement.classList.remove('is-not-overflow');
    } else {
      console.error('Body element not found.');
    }
  }

  onSubmit() {
    const formData = new FormData();
    this.submitted = true;
    if (this.addMedicalTestForm.invalid) {
      return;
    }
    for (let field in this.addMedicalTestForm.value) {
      formData.append(field, this.f[field].value);
    }
    formData.append('duration', `${this.f.time.value} ${this.f.duration.value}`);
    if (this.medicalTest && this.medicalTest.images) {
      if (this.imagesToDelete.length > 0) {
        formData.append('imagesToDelete', [this.imagesToDelete.map(image => image)].toString());
      }
      if (this.uploadedImages.length <= 0) {
        formData.append('images[]', JSON.stringify([]));
      }
    } 
    this.uploadedImages.forEach(image => formData.append('images[]', image));
    const popUpConfig: SweetAlertOptions = {
      title: '',
      html: 'Medical Test Posted',
      icon: 'success',
      confirmButtonColor: '#00ACB1'
    };
    if (this.medicalTest){
      this.medicalTestService.editMedicalTest(this.medicalTest.id, formData)
        .pipe(first()).subscribe(medicalTest => {
          popUpConfig.html = 'Medical Test Edited'; 
          Swal.fire(popUpConfig).then(() =>{
            this.dataService.saveDataToView(medicalTest);
            this.dataService.clearMedicalTest();
            this.router.navigate(['medical-test-details']);
          });
      }, error => {
        popUpConfig.title = 'An error occurred';
        popUpConfig.html = 'Please contact us at: <b>info@meshincentre.com</b>'; 
        popUpConfig.icon = 'error';
        popUpConfig.cancelButtonColor = '#f37b7b'
        popUpConfig.cancelButtonColor = ''
        Swal.fire(popUpConfig);
      })
    } 
    else {
      this.medicalTestService.postMedicalTest(formData)
      .pipe(first()).subscribe(medicalTest => {
        Swal.fire(popUpConfig).then(() => {
          this.dataService.saveDataToView(medicalTest);
          this.router.navigate(['medical-test-details']);
        });
      },
        error => {
          popUpConfig.title = 'An error occurred';
          popUpConfig.html = 'Please contact us at: <b>info@meshincentre.com</b>'; 
          popUpConfig.icon = 'error';
          popUpConfig.cancelButtonColor = '#f37b7b'
          popUpConfig.cancelButtonColor = ''
          Swal.fire(popUpConfig);
        });
    }
    
  }
}

