<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9">
  <div class="section">
    <div class="container">
      <form [formGroup]="addOrderForm" >
        <div class="row">
          <div class="col-lg-8">
            <div class="sigma_form style-7">
              <div class="form-block">
                <h5>Your Information:</h5>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Name</label>
                      <i class="fal fa-user"></i>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        [attr.disabled]="true"
                        formControlName="clientName"
                      >
                      <div *ngIf="submitted && f.clientName.errors" class="is-invalid">
                        <div *ngIf="f.clientName.errors.required">Name required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Email</label>
                      <i class="fal fa-at"></i>
                      <input 
                        type="email" 
                        class="form-control"
                        placeholder="email"
                        [attr.disabled]="true"
                        formControlName="email"
                      >
                      <div *ngIf="submitted && f.email.errors" class="is-invalid">
                        <div *ngIf="f.email.errors.required">Email required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Date of birth</label>
                      <i class="fal fa-calendar-alt"></i>
                      <input 
                        type="text"  
                        data-provide="datepicker"
                        class="form-control"
                        placeholder="'Date of birth'"
                        [attr.disabled]="true"
                        formControlName="birthDate"
                      >
                      <div *ngIf="submitted && f.birthDate.errors" class="is-invalid">
                        <div *ngIf="f.birthDate.errors.required">Birth Date required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Phone No.</label>
                      <i class="fal fa-phone"></i>
                      <input 
                        type="text" 
                        class="form-control"
                        placeholder="+256742345678"
                        formControlName="telephone"
                      >
                      <div *ngIf="submitted && f.telephone.errors" class="is-invalid">
                        <div *ngIf="f.telephone.errors.required">Telephone required</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-block">
                <h5>Test Order Information:</h5>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Day when available</label>
                      <input 
                        type="date"  
                        data-provide="datepicker"
                        class="form-control"
                        placeholder="'Date of Availability'"
                        formControlName="availability"
                        [min]="disableDate"
                      >
                      <div *ngIf="submitted && f.availability.errors" class="is-invalid">
                        <div *ngIf="f.availability.errors.required">Day when available required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>District</label>
                      <select 
                        class="form-control"
                        placeholder="District"
                        formControlName="district"
                      >
                      <option value="" disabled selected>District</option>
                      <option *ngFor="let location of locations" [value]="location">{{ location }}</option>
                      </select>
                      <div *ngIf="submitted && f.district?.errors?.required" class="is-invalid">
                        District required
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Precise Location</label>
                      <i class="fal fa-location-arrow"></i>
                      <textarea  
                        rows="5" 
                        class="form-control"
                        placeholder="Give precise location details"
                        formControlName="location"
                      >
                      </textarea>
                      <div *ngIf="submitted && f.location.errors" class="is-invalid">
                        <div *ngIf="f.location.errors.required">Location required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Additional Notes</label>
                      <textarea  
                        rows="7" 
                        value="" 
                        class="form-control"
                        placeholder="Note To Us"
                        formControlName="note"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-block">
                <h4>Payment:</h4>
                <div class="row mt-3">
                  <div class="col-lg-6">
                    <div align="center">
                      <form 
                        method="post" 
                        style="margin:0px" 
                        ngNoForm  
                        [action]="'https://payments.yo.co.ug/webexpress/'"
                      >
                        <button 
                          type="submit" 
                          [disabled]="addOrderForm.invalid"
                          class="sigma_btn btn-block btn-sm mt-4 pay-button"
                        >
                          {{ 'Pay ' + getTotalCost() + ' UGX' }}
                          <i class="fal fa-arrow-right ml-3"></i>
                        </button>
                        <input type="hidden" name="bid" value="319" />
                        <input type="hidden" name="currency" value="UGX" />
                        <input type="hidden" name="amount" [value]="getTotalCost()" />
                        <input type="hidden" name="narrative" [value]="getNarrative()" />
                        <input type="hidden" name="reference" value="" />
                        <input type="hidden" name="provider_reference_text" value="Thank you for confirming your payment." />
                        <input type="hidden" name="account" value="100876602113" />
                        <input type="hidden" name="return" value="http://localhost:4200/payment-success" />
                        <input type="hidden" name="prefilled_payer_email_address" [value]="user?.email" />
                        <input type="hidden" name="prefilled_payer_mobile_payment_msisdn" value="" />
                        <input type="hidden" name="prefilled_payer_names" [value]="user?.firstname + ' ' + user?.lastname" />
                        <input type="hidden" name="abort_payment_url" value="http://localhost:4200/payment-abort" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="sidebar style-10 mt-5 mt-lg-0">
              <!-- Booking Widget -->
              <div class="widget widget-booking">
                <h5 class="widget-title">Order Summary</h5>
                <div class="test-names-container">
                  <span class="order-summary-title pb-2">
                    {{addConsultation &&  cartItems.length < 0 ? 'Test Name(s)' : 'Items Ordered'}}
                  </span>
                    <ul class="test-names">
                      <li *ngFor="let item of cartItems; let i = index;">
                        {{(i + 1) + '. ' + item.name }}
                      </li>
                      <li *ngIf="addConsultation">
                        {{ (cartItems.length + 1) + '. ' }} Medical Consultation
                      </li>
                    </ul>
                </div>
                <hr>
                <ul>
                  <li class="d-flex align-items-center justify-content-between">
                    <span class="order-summary-title">Availability</span>
                    <span>{{f.availability.value}}</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span class="order-summary-title">Name</span>
                    <span>{{f.clientName.value}}</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span class="order-summary-title">District</span>
                    <span>{{f.district.value}}</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span class="order-summary-title">Telephone</span>
                    <span>{{f.telephone.value}}</span>
                  </li>
                </ul>
                <hr>
                <ul>
                  <li 
                    *ngIf="cartItems.length > 0"
                    class="d-flex align-items-center justify-content-between">
                    <span class="order-summary-title">Total Tests</span>
                    <span>{{ cartItems.length }}</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span class="order-summary-title">Total Cost</span>
                    <span>{{ getTotalCost() + ' UGX' }}</span>
                  </li>
                  <li 
                    *ngIf="cartItems.length > 0"
                    class="d-flex align-items-center justify-content-between">
                    <span class="order-summary-title">Sample Pickup Fee</span>
                    <span>0 UGX</span>
                  </li>
                </ul>
                <hr>
                <ul>
                  <li class="d-flex align-items-center justify-content-between">
                    <span class="order-summary-title secondary-color"><b>Total Cost</b></span>
                    <span class="secondary-color"><b>{{ getTotalCost() + ' UGX' }}</b></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
