import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MedicalTest, Order } from '../../models';
import {CartService, DataService } from '../../services';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})

export class OrderDetailsComponent implements OnInit {
  orderDetails: MedicalTest[];
  order: Order;
  totalPrice: number;
  cartItems: MedicalTest[] = [];
  addConsultation: boolean;
  isReOrderConsultation: boolean;
  consultationPrice: number = 30000;

  constructor(
    private router: Router,
    private cartService: CartService,
    private dataService: DataService,
  ) {
  }

  ngOnInit(): void {
    this.dataService.getDataToView().subscribe(dataToView => {
      if (dataToView !== null) {
        this.order = dataToView as Order;
        this.addConsultation = !!this.order.medical_consultation;
        this.orderDetails = this.order.medical_tests as MedicalTest[];
        this.totalPrice = this.getTotalCost(this.orderDetails);
      } else {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  getTotalCost(orderDetails: MedicalTest[]): number {
    return this.dataService.getTotalCost(orderDetails, this.addConsultation);
  }

  toggleConsultation(event: Event) {
    this.dataService.saveAddConsultationStatus(this.isReOrderConsultation);
  }

  addToCart(medicalTest: MedicalTest) {
    this.cartService.getCartMedicalTests().subscribe(cartItems => this.cartItems = cartItems);
    this.dataService.addToCart(medicalTest, this.cartItems);
  }
}
