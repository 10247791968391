import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, CartService, OrderService, DataService } from '../../services';
import Swal from 'sweetalert2';
import { first, mergeMap } from 'rxjs/operators';
import { MedicalTest, Order, User } from 'src/app/models';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  user: User;
  cartItems: MedicalTest[] = [];

  constructor (
    private authenticationService: AuthenticationService,
    private cartService: CartService,
    private dataService: DataService,
    private orderService: OrderService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user = this.authenticationService.currentUser()['user'];
    const storedOrder = this.dataService.getFromLocalStorage('orderData', {});
    const cartItems = this.dataService.getFromLocalStorage<MedicalTest[]>('cartItems', []);
    const medicalConsultation = this.dataService.getFromLocalStorage<boolean>('consultation', false);

    if (storedOrder) {
      const formData = new FormData();
      for (const [key, value] of Object.entries(storedOrder)) {
        formData.append(key, value as string);
      }
      formData.append('medicalTestIds[]', cartItems.map(item => item.id).toString());
      formData.append('medicalConsultation', medicalConsultation.toString());
      this.orderService.addOrder(formData).pipe(
        mergeMap(response => {
          const emptyCartFormData = new FormData();
          emptyCartFormData.append('medicalTestIds[]', '');
          emptyCartFormData.append('medicalConsultation','false'); 
          return this.cartService.updateCart(this.user.id as number, emptyCartFormData).pipe(first());
        })
      ).subscribe(
        () => {
          this.cartItems = [];
          this.goToOrder();
        },
        error => {
          console.error('Error occurred:', error);
          Swal.fire({
            title: 'An error occurred',
            html: 'Please contact us at: <b>info@go-checks.com</b>',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
        });
    }
  }

  goToOrder() {
    if (this.authenticationService.currentUser()) {
      this.user = this.authenticationService.currentUser()['user'];
      this.orderService.getMyOrders(this.user.id).subscribe(orders => {
        const myOrders: Order[] = orders as Order[];
        this.dataService.saveDataToView(
          myOrders.find(order => order.status !== 'Order Complete') as Order
        );
        this.router.navigate(['order-timeline']);
      });
    }
  }
}
