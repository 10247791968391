import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../helper/helper.service';
import { CartService, DataService, AuthenticationService } from '../../services';
import { Cart, MedicalTest, NavigationDetails, User } from '../../models';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends HelperService implements OnInit {
  navigation: NavigationDetails[];
  cartItems: MedicalTest[] = [];
  isLoggedIn: Observable<boolean>;
  isAdmin: Observable<boolean>;
  user: User;
  navMethod: boolean = false; 

  constructor(
    public router: Router,
    private dataService: DataService,
    private authenticationService: AuthenticationService
  ) {
    super();
  }

  ngOnInit() {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.isAdmin = this.authenticationService.isAdminLoggedIn();
    this.navigation = this.dataService.getNavigationDetails();
  }

  toggleNav(): void {
    this.navMethod = !this.navMethod; 
  }

}
