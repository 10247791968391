import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../helper/helper.service';
import { CartService, DataService, AuthenticationService } from '../../services';
import { MedicalTest, User } from '../../models';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-nav-links',
  templateUrl: './nav-links.component.html',
  styleUrls: ['./nav-links.component.scss']
})
export class NavLinksComponent extends HelperService implements OnInit {
  @Input() showSpan: boolean = false; 
  @Input() showCart: boolean = true; 
  @Output() navToggle: EventEmitter<void> = new EventEmitter();
  cartItems: MedicalTest[] = [];
  isLoggedIn: Observable<boolean>;
  isAdmin: Observable<boolean>;
  user: User;
  navMethod: boolean = false; 
  addConsultation: boolean = false;
  cartItemCount: number = 0; 

  constructor(
    public router: Router,
    private cartService: CartService,
    private authenticationService: AuthenticationService,
    private dataService: DataService,
  ) {
    super();
  }

  ngOnInit() {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.isAdmin = this.authenticationService.isAdminLoggedIn();
    this.cartService.getCartMedicalTests().pipe(
      mergeMap((cartTests: MedicalTest[])  => {
        this.cartItems = cartTests;
        return this.dataService.getConsultationStatus();
      })
    ).subscribe((status: boolean) => {
      this.addConsultation = status;
      this.updateCartBadge();
    });
  }

  updateCartBadge() {
    const cartItemCount = this.cartItems.length + (this.addConsultation ? 1 : 0);
    this.cartItemCount = cartItemCount; 
 }

  toggleNav(): void {
    this.navToggle.emit(); 
  }

  goToCart() {
    if (this.cartItems.length === 0 && !this.addConsultation) {
      Swal.fire({
        title: '',
        html: '<h4>Your Cart</h4>There are no items in your cart',
        icon: 'info',
        confirmButtonColor: 'var(--thm-base)',
        customClass: {
          icon: 'sweet-alert-icon-class'
        },
        showClass: {
          popup: 'sweet-alert-slow-animation-show'
        },
        hideClass: {
          popup: 'sweet-alert-slow-animation-hide'
        }
      });
    } else {
      this.router.navigateByUrl('cart');
    }
  }

  onLogout() {
    this.authenticationService.logout();
    this.toggleNav(); 
  }
}
