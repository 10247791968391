import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import{ Cart, MedicalTest, Order, User } from '../../models';
import { 
  AuthenticationService, 
  CartService, 
  DataService,
  DocumentService, 
  MedicalTestService,
  OrderService 
} from '../../services';
import { catchError, map, mergeMap } from 'rxjs/operators';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { of } from 'rxjs';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  cartItems: MedicalTest[] = [];
  user: User;
  recommendedMedicalTests: MedicalTest[] = [];
  medicalTests: MedicalTest[] = [];
  slickCarouselConfig: any;
  addConsultation: boolean = false;
  consultationPrice: number = 30000;

  constructor (
    private router: Router,
    private authenticationService: AuthenticationService,
    private cartService: CartService,
    private dataService: DataService,
    private documentService: DocumentService,
    private medicalTestService: MedicalTestService,
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
    this.slickCarouselConfig = {...this.documentService.getslickCarouselConfig(),
      infinite: false, 
      slidesToShow: 1, 
      slidesToScroll: 1 
    };
    this.user = this.authenticationService.currentUser()['user'];
    this.cartService.getCartMedicalTests().subscribe(cartMedicalTests => {
      this.cartItems = cartMedicalTests;
      this.getMedicalTestsRecommendations();
    });
    this.dataService.getConsultationStatus().subscribe(status => 
      this.addConsultation = status
    );
  }

  removeFromCart(test: MedicalTest) {
    this.cartService.removeFromCart(test);
  }

  getTotalCost(): number {
    return this.dataService.getTotalCost(this.cartItems, this.addConsultation);
  }

  toggleConsultation(event: Event) {
    this.dataService.saveAddConsultationStatus(this.addConsultation);
  }
    
  getMedicalTestsRecommendations() {
    this.dataService.getAllMedicalTests().pipe(
      mergeMap(medicalTests => {
        this.medicalTests = medicalTests;
        if (!this.medicalTests.length) {
          return []; 
        }
        const formData = new FormData();
        formData.append('medicalTestIds[]', this.cartItems.map(item => item.id).toString());
        return this.medicalTestService.getRecommendedMedicalTests(formData);
      })
    ).subscribe(response => {
      this.recommendedMedicalTests = response as MedicalTest[];
    });
  }
  
  addToCart(medicalTest: MedicalTest) {
    this.dataService.addToCart(medicalTest, this.cartItems);
  }

  goToMedicalTestDetails(medicalTest: MedicalTest) {
    this.dataService.saveDataToView(medicalTest);
    this.router.navigate(['medical-test-details']);
  }

  checkout() {
    const formData = new FormData();
    formData.append('medicalTestIds[]', 
      (this.cartItems.length === 0 && this.addConsultation) ? 
        '' : this.cartItems.map(item => item.id).toString());
  
    formData.append('medicalConsultation', this.addConsultation.toString()); 
    const popUpConfig: SweetAlertOptions = {
      title: 'An error occurred',
      html: 'Please contact us at: <b>info@gochecks.com</b> or via the contact form',
      icon: 'error',
      confirmButtonColor: '#f37b7b',
      confirmButtonText: 'Ok',
      showCancelButton: false,
      cancelButtonColor: '#f37b7b',
      cancelButtonText: 'Try Again'
    };
    this.orderService.getMyOrders(this.user.id).subscribe((orders) => {
      const userOrders = orders as Order[];
      const ongoingOrders = userOrders.filter(
        (order) => order.status !== 'Order Complete'
      );
      if (ongoingOrders.length > 0) {
        // Display a warning message if there are ongoing orders
        popUpConfig.title = 'Ongoing Orders';
        popUpConfig.html = 'You have ongoing orders that need to be completed before placing a new order.';
        popUpConfig.icon = 'warning';
        Swal.fire(popUpConfig);
        return;
      }

      // Proceed with checkout if there are no ongoing orders
      if (this.cartItems.length > 0 || this.addConsultation) {
        this.cartService.getUserCart(this.user.id).pipe(
          mergeMap(response => {
            if (response.message === 'No associated cart') {
              return this.cartService.createCart(formData);
            } else {
              return this.cartService.updateCart(this.user.id as number, formData);
            }
          }), catchError(error => {
            Swal.fire(popUpConfig);
            return of(error);
          })
        ).subscribe(
        (response: any) => { 
          if (response.message === 'Cart updated' || (response.id && response.medical_test_ids)) {
            this.router.navigate(['add-order']);
          } else if (response.message === 'Error when updating cart') {
            Swal.fire(popUpConfig).then(() => {
              this.router.navigate(['dashboard']);
            });;
          }
        }, error => {
          Swal.fire(popUpConfig).then(() => {
            this.router.navigate(['dashboard']);
          });;
        });
      } else {
          Swal.fire({
            title: '',
            html: '<h4>Your Cart</h4>There are no items in your cart',
            icon: 'info',
            confirmButtonColor: 'var(--thm-base)',
            customClass: {
              icon: 'sweet-alert-icon-class'
            },
            showClass: {
              popup: 'sweet-alert-slow-animation-show'
            },
            hideClass: {
              popup: 'sweet-alert-slow-animation-hide'
            }
          }).then(() => {
            this.router.navigate(['medical-tests']);
          });
      };
    });
  }
  
}
