<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9 service-details">
  <div class="section sigma_post-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="sigma_post-details-inner mb-md-80">
            <div class="entry-content">
              <br>
              <ng-container *ngIf="otherService && otherService.images && otherService.images.length > 0">
                <img 
                  [src]="selectedImageIndex === 0 ? otherService.images[0] : selectedImage" 
                  [alt]="otherService.title" 
                  class="medical-test-image w-100">
              </ng-container>       
              <div class="row">
                <div class="col-md-12 image-carousel" *ngIf="otherService && otherService.images && otherService.images.length > 1">
                  <div *ngFor="let image of otherService.images; let i = index;">
                    <img [src]="image" [alt]="otherService.title" (click)="selectImage(i)">
                  </div>
                </div>
              </div>                          
              <h4 class="entry-title">{{otherService?.title}}</h4>
              <div class="pt-1" [innerHtml]="otherService?.description"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <app-service-sidebar 
            [otherService]="otherService"
            [isShowAddCart]="isShowAddCart"
            [isMedicalConsultation]="isMedicalConsultation"
            >
          </app-service-sidebar>
        </div>
      </div>
    </div>
  </div>
</div>
