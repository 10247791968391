<app-breadcrumbs></app-breadcrumbs>
<div class="admin-area pt-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-3">
        <div class="admin-area-item">
          <div class="admin-area-left">
            <div class="single-profile-contact">
              <h5>Admin Panel</h5>
              <ul>
                <li *ngIf="canViewUsers">
                  <i class="icofont-users-social"></i>
                  <a [ngClass]="{'is-active': isUsers}" (click)="getAllUsers()">
                    Users
                  </a>
                </li>
                <li><i class="icofont-archive"></i>
                  <a [ngClass]="{'is-active': isMedicalTests}" (click)="getAllMedicalTests()">
                    Medical Tests
                  </a>
                </li>
                <li *ngIf="canViewOrders">
                  <i class="icofont-clip-board"></i>
                  <a [ngClass]="{'is-active': isOrders}" (click)="getAllOrders()">
                    Orders
                  </a>
                </li>
                <li *ngIf="isSuperAdmin"><i class="icofont-clip-board"></i>
                  <a [ngClass]="{'is-active': isAllAdmins}" (click)="getAllAdmins()">
                    Permissions
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <div class="admin-area-item">
          <div class="admin-area-right">
            <div *ngIf="isMedicalTests && canAddMedicalTest">
              <a routerLink="/add-medical-test" class="sigma_btn btn-sm mb-3">
                Add
                <i class="fal fa-plus"></i>
              </a>
            </div>
            <div *ngIf="isAllAdmins && isSuperAdmin">
              <a routerLink="/permissions" class="sigma_btn btn-sm mb-3">
                Configure Scope
                <i class="fal fa-plus"></i>
              </a>
            </div>
            <div class="admin-panel" id="admin-panel">
              <app-table
                *ngIf="isUsers && canViewUsers"
                [tableTitle]="'Users'"
                [tableColumnTitles]="titles"
                [tableData]="tableData"
              >
                <div tablebody>
                  <ng-template let-data let-i="index" #table>
                    <td>{{i + 1}}</td>
                    <td>{{data['firstname']}}</td>
                    <td>{{data['lastname']}}</td>
                    <td>{{data['gender']}}</td>
                    <td>{{data['address']}}</td>
                    <td *ngIf="isSuperAdmin">
                      <i 
                        class="fal fa-trash" 
                        (click)="onDeleteUser(data)"
                        [ngClass]="canViewUsers ? '': 'is-inactive'"
                      ></i>
                    </td>
                  </ng-template>
                </div>
              </app-table>

              <app-table
                *ngIf="isMedicalTests"
                [tableTitle]="'Medical Tests'"
                [tableColumnTitles]="titles"
                [tableData]="tableData"
              >
                <div tablebody> 
                  <ng-template let-data let-i="index" #table>
                    <td>{{i + 1}}</td>
                    <td>{{data['name']}}</td>
                    <td>{{data['category']}}</td>
                    <td>{{data['price']}}</td>
                    <td  *ngIf="isSuperAdmin">{{data['partner_price']}}</td>
                    <td>
                      <i 
                        class="fal fa-edit" 
                        (click)="onEditMedicalTest(data)" 
                        [ngClass]="canEditMedicalTests ? '': 'is-inactive'"
                      >
                      </i>
                      <i 
                        class="fal fa-trash" 
                        (click)="onDeleteMedicalTest(data)"
                        [ngClass]="canEditMedicalTests ? '': 'is-inactive'"
                      ></i>
                    </td>
                  </ng-template>
                </div>
              </app-table>

              <app-table
                *ngIf="isOrders && canViewOrders"
                [tableTitle]="'Orders'"
                [tableColumnTitles]="titles"
                [tableData]="tableData"
              >
                <div tablebody>
                  <ng-template let-data let-i="index" #table>
                    <td>{{i + 1}}</td>
                    <td>
                      <ul>
                        <li *ngFor="let test of data.medical_tests let i=index">
                          {{i + 1 + '. ' + test.name}}
                        </li>
                        <li *ngIf="data.medical_consultation">
                          {{ (data.medical_tests?.length || 0) + 1 + '. ' }} Medical Consultation
                        </li>
                      </ul>
                    </td>
                    <td>{{ data.order_number }}</td>
                    <td>{{ data.fullName }}</td>
                    <td>{{data['telephone']}}</td>
                    <td>{{data['location']}}</td>
                    <td [ngClass]="{
                      'confirmation': data['status'] === 'Order Confirmation',
                      'collection': data['status'] === 'Sample Collection',
                      'processing': data['status'] === 'Sample Processing',
                      'results': data['status'] === 'Results Processing',
                      'complete': data['status'] === 'Order Complete'
                    }">
                      {{data['status']}}
                    </td>
                    <td>
                      <i 
                        class="fal fa-edit" 
                        (click)="onEditOrder(data)"
                        [ngClass]="canEditOrders ? '': 'is-inactive'"
                      ></i>
                      <i 
                        class="fal fa-trash" 
                        (click)="onDeleteOrder(data)"
                        [ngClass]="canEditOrders ? '': 'is-inactive'"
                      ></i>
                    </td>
                  </ng-template>
                </div>
              </app-table>
              <app-table
                *ngIf="isAllAdmins && isSuperAdmin"
                [tableTitle]="'Admins'"
                [tableColumnTitles]="titles"
                [tableData]="tableData"
              >
                <div tablebody>
                  <ng-template let-data let-i="index" #table>
                    <td>{{i + 1}}</td>
                    <td>{{data['firstname'] + ' ' + data['lastname']}}</td>
                    <td>{{roleLabels[data.role?.name]}}</td>
                    <td>
                      <button class="sigma_btn btn-sm view-btn mt-2 mb-2" (click)="editRole(data)">Edit</button>
                    </td>
                    <td [innerHTML]="rolePermissions[data.role?.name]"></td>
                  </ng-template>
                </div>
              </app-table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
