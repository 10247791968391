import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MedicalTest } from '../../models';
import { CartService, DataService } from '../../services';

@Component({
  selector: 'app-medical-test-details',
  templateUrl: './medical-test-details.component.html',
  styleUrls: ['./medical-test-details.component.scss']
})

export class MedicalTestDetailsComponent implements OnInit {
  cartItems: MedicalTest[] = [];
  medicalTest: MedicalTest;
  selectedImageIndex: number = 0;
  selectedImage: string = '';
  isShowAddCart: boolean = true;
  isMedicalConsultation: boolean = false;

  constructor(
    private cartService: CartService,
    private dataService: DataService,
    private router: Router,
    ) {
  }

  ngOnInit(): void {
    this.dataService.getDataToView().subscribe(dataToView => {
      if (dataToView !== null) {
        this.medicalTest = dataToView as MedicalTest;
      } else {
        this.router.navigate(['/medical-tests']);
      }
    });
    this.cartService.getCartMedicalTests().subscribe(cartItems => this.cartItems = cartItems);
  }

  selectImage(index: number) {
    if (this.medicalTest && this.medicalTest.images && this.medicalTest.images.length > 1) {
      this.selectedImageIndex = index;
      this.selectedImage = this.medicalTest.images[index];
    }
  }

}
